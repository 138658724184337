body[data-module='messaging'].mobile_application {
  height: calc(100vh - 40px);
  overflow: hidden;
  margin-bottom: 0px;
}

.mobile_application {
  #messaging-container {
    height: calc(100vh - 50px);
    min-height: 300px;
  }
}

#messaging-container {
  height: calc(100vh - 450px);
  min-height: 750px;
  display: flex;
  flex-direction: row;

  &.card {
    color: $s-blue !important;
  }

  .avatar {
    flex: 0 0 50px;
    height: 50px;
    margin: 0.5rem 1rem 0.5rem 0.5rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  i.unread {
    color: $s-blue-light;
  }
  i.starred {
    color: $sun-medium;
  }

  #conversations-list {
    height: 100%;
    border-right: 1px solid #f0f0f0;
    flex: 0 0 400px;
    min-width: 0px;

    display: flex;
    flex-direction: column;

    #conversations-search {
      padding: 10px;
      flex: 0 0 auto;
      border-bottom: 1px solid #f0f0f0;
      position: relative;

      #search-container {
        border: solid 1px #f0f0f0;
        border-radius: 20px;
        position: relative;

        input {
          border: 0;
          background-color: transparent;
          height: auto;
          margin: 0.2em 0.5rem;
        }
        .search-icon {
          color: $s-red;
          position: absolute;
          right: 0.5em;
          top: calc(50% - 0.5em);
        }
      }
    }

    #conversations {
      flex: 1 1 auto;
      overflow-y: scroll;
      min-height: 0%;

      .conversations-list-item {
        cursor: pointer;

        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 10px;

        .conversation-item-content {
          height: auto;
          flex: 1;
          min-width: 0%;
          display: flex;
          flex-wrap: wrap;

          .conversation-item-header {
            flex: 1 0 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .conversation-item-title {
              font-weight: bold;
              margin-right: 1rem;
              margin-bottom: 0;
            }
            .conversation-item-time {
              color: #a2a2a2;
              margin-bottom: 0;
            }
          }
          .conversation-item-message {
            text-overflow: ellipsis;
            flex: 1 1 1px;
            min-width: 0%;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .conversation-item-icons {
            flex: 0 0 auto;
            min-width: 0%;
            margin-right: 0.5rem;
            display: flex;
            gap: 5px;
            padding-top: 3px;

            i {
              padding-top: 0.3rem;
            }

            span.pending,
            span.declined,
            span.new {
              font-size: 0.8rem;
              color: white;
              border-radius: 12.5px;
              padding: 0 0.5rem;
            }

            .pending {
              background-color: $s-blue-light;
            }
            .new {
              background-color: $s-green;
            }
            .declined {
              background-color: $s-red;
            }
          }
        }
      }
    }
  }

  #conversation-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 0%;

    #conversation-header {
      flex: 0 0 75px;
      padding: 10px;
      border-bottom: 1px solid #f0f0f0;

      display: flex;
      flex-display: row;
      align-items: center;

      .back-button {
        display: none;
      }

      .conversation-header-info {
        flex: 1 1 auto;
        .conversation-header-title {
          font-weight: bold;
          font-size: 1.2rem;
          margin-right: 1rem;
        }
      }

      .conversation-header-actions {
        flex: 0 0 auto;
        margin-right: 2rem;

        button {
          margin-bottom: 0;
          background: transparent;
          i {
            font-size: 1.5rem;
          }
        }
      }
    }

    #messages-list {
      flex: 1 1 auto;
      overflow-y: scroll;
      height: calc(100% - 200px);
      padding: 10px;
      transition: scroll-behavior 0.5s ease-in-out;

      .message-enter-active,
      .message-leave-active {
        transition: all 0.5s;
      }
      .message-enter,
      .message-leave-to {
        opacity: 0;
        transform: translateY(30px);
      }

      .message {
        border-radius: 15px;
        max-width: 40%;
        padding: 0.5rem;
        padding-left: 1rem;
        position: relative;
        margin: 1rem;
        white-space: pre-wrap;

        &.display-time {
          margin-bottom: 3rem;
        }

        .message-time {
          position: absolute;
          bottom: -1.5rem;
          font-size: 0.8rem;
          right: 0.5rem;
          color: gray;
        }
      }

      .message.left {
        border-top-left-radius: 0;
        background-color: #f0f0f0;
      }

      .message.right {
        margin-left: auto;
        border-top-right-radius: 0;
        background-color: #cbe0f3;
      }
    }

    #message-input {
      flex: 1 0 auto;
      padding: 10px;
      position: relative;

      .scroll-button-container {
        position: absolute;
        top: -50px;
        text-align: center;
        width: 100%;
        button {
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
          animation: slide-up 0.5s ease-in-out;
        }
      }

      .textarea-container {
        border: solid 1px #f0f0f0;
        border-radius: 5px;
        width: 100%;
        position: relative;
      }

      #message {
        min-height: 70px;
        min-width: 0%;
        max-height: 30vh;

        overflow-y: scroll;
        white-space: pre-wrap;

        padding: 0.5rem 40px 0.5rem 0.5rem;

        background-color: transparent;
        border: 0;

        &:empty:before {
          color: gray;
          content: attr(placeholder);
          pointer-events: none;
          display: block; /* For Firefox */
        }
      }

      #send-message {
        position: absolute;
        bottom: 0.5em;
        right: 1em;
      }
    }
  }
}

//Mobile selectors
@include media-breakpoint-up(md) {
  .conversations-list-item.active {
    background-color: #f0f0f0;
    border-left: solid 4px $s-green;
  }
}

@include media-breakpoint-only(md) {
  body[data-module='messaging'] {
    .container {
      max-width: 100%;
      margin-top: 1rem;
    }
  }

  #messaging-container {
    #conversations-list {
      flex: 0 1 50%;
      min-width: 0%;
    }
  }
}
@include media-breakpoint-down(md) {
  body[data-module='messaging'] {
    .container {
      padding: 0;
      margin-top: 0;
    }

    .card {
      border: 0;
      box-shadow: none;
    }
  }

  #messaging-container {
    #conversations-list.conversation-selected {
      display: none;
    }

    #conversation-container:not(.conversation-selected) {
      display: none;
    }

    #conversations-list {
      flex: 1 0 100vw;
      min-width: 0%;
    }

    #conversation-container {
      #conversation-header {
        .back-button {
          display: block;
          flex: 0 0 auto;

          button {
            padding-right: 0.5rem;
            padding-top: 1.3rem;
          }

          i {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.connection-request-modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  i {
    position: absolute;
    font-size: 100px;
    left: 20px;
    bottom: 40px;
    color: #d7d7d7;

    i.fa-times {
      bottom: -40px;
    }
  }
}

.mobile_application {
  .s-modal-popup {
    width: 100vw;
    height: calc(100vh - 80px);
    overflow: scroll;
    padding: 0 2rem;
    margin-top: 50px;

    .close-button {
      top: 70px !important;
    }
  }

  .connection-request-modal {
    .personal-profile-badge {
      display: none;
    }
  }
}
.connection-request-modal {
  z-index: 1;

  h2 {
    color: $s-blue;
  }

  textarea {
    height: auto;
  }

  .s-model-content {
    min-height: 180px;
  }

  .s-modal-footer {
    justify-content: space-between;
    align-items: end;
    margin-top: 2rem;
  }

  .personal-profile-badge {
    border: solid 1px #e0e0e0;
    background-color: #f7f7f7;
    border-radius: 0.5em;
    min-width: 300px;
    padding: 1em;

    gap: 1em;

    display: flex;
    align-content: center;

    .profile-picture {
      flex: 0 0 auto;
    }

    .profile-info {
      flex: 1 1 auto;
    }
  }
  button {
    width: auto !important;
  }
}
