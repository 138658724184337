.mobile_application .event-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 2rem;

  .event-box-container {
    flex: 0 0 80vw;
  }
}

.event-box {
  @include box($white, $gray-800);
  border: 2px solid $gray-200;
  border-radius: 5px;
  box-shadow: unset;
  min-height: 280px;
  width: 100%;
  position: relative;

  &.no-border {
    border: unset;
    min-height: unset;
  }

  .event-box-visual {
    border-radius: 5px;
    height: 200px;
    position: relative;
    width: 100%;

    .event-box-logo {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 3px;
      left: 50%;
      padding: 5px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      &.side-event {
        img {
          max-width: 200px;
        }
      }
    }
  }

  .event-box-info {
    display: inline-block;
    padding: 10px 0;

    .event-title {
      height: 85px;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.side-event-header {
  .container {
    margin-bottom: 0px;

    .side-event-title {
      background: $white;
      border-radius: 10px 10px 0px 0px;
      display: inline-block;
      margin: 0px -40px 0px -40px;
      padding: 20px 40px;

      h3 {
        margin-bottom: 10px;
      }
    }
  }
}

.side-event-nav {
  background: $white;
  margin-bottom: 0px;
  margin-top: 0px;

  .event-nav {
    box-shadow: unset;
    padding: 20px 0px;

    .navbar-nav {
      margin: unset;
    }
  }
}

.event-nav {
  background: $white;
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 10%);
  font-size: 1rem;
  margin: 0px auto;
  padding: 25px 3%;
  width: 100%;

  .navbar-toggler-icon {
    background: none;
    display: inline;
    margin: 0px 15px;
  }

  .navbar-nav {
    margin: 0px auto;
  }

  .nav-link {
    color: $gray-800;
    border-bottom: $white solid 2px;
    font-size: 1.1rem;
    height: 45px;
    margin: 5px 15px 0px 3px;
    padding: 5px 5px -5px 0px;
    -webkit-transition: 0.3s ease-in-out; /* For Safari 3.0 to 6.0 */
    transition: 0.3s ease-in-out; /* For modern browsers */

    &:hover:not(.tickets):not(.language):not(.organizing-logo) {
      color: $s-orange;
      border-bottom: $s-orange solid 2px;
      cursor: pointer;
    }

    &.home {
      margin: 5px 0px 0px 0px;

      &:hover {
        border-bottom: $white solid 2px;
      }
    }

    i {
      margin-right: 10px;
      text-align: center;
      width: 15px;
    }

    &.tickets {
      margin: -4px 10px 0px 0px;

      button {
        border: 2px solid $white;
        padding: 8px 15px;

        i {
          margin-left: 0px;
        }
      }

      &.only-mobile {
        margin-top: -7px;
        padding: 5px;

        button {
          font-weight: 600;
        }

        i {
          margin-right: 5px;
        }
      }

      &:hover {
        button {
          background: $white;
          border: 2px solid $s-orange;
          color: $s-orange;
          transition: 0.1s ease-in-out;
          -webkit-transition: 0.1s ease-in-out;
        }

        i {
          margin-left: 0px;
        }
      }
    }
  }

  .language {
    button {
      background: $gray-200;
      border: 2px solid $gray-200;
      border-radius: 100px;
      color: $s-orange;
      margin: -8px 0px 0px 0px;
      padding: 2px;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      width: 100px;

      i {
        font-size: 1.8rem;
        padding: 5px 0px 0px 0px;
      }

      &:focus {
        border-radius: 20px 20px 0 0;
        box-shadow: unset;
      }

      &:hover {
        border: 2px solid $gray-200;
        background: $white;
      }
    }

    ul {
      border: 2px solid $gray-200;
      margin: -3px 0px 0px 0px;
      min-width: unset;
      padding: 3px 0 0 0;
      width: 100px;

      li {
        a {
          color: $gray-800;
          margin-bottom: 0;
          padding: 5px 10px;

          &:active {
            background: $gray-200;
          }
        }
      }
    }
  }
}

.organizing-logos {
  display: flex;
  flex-direction: row;
  align-items: center;

  .organizing-logo {
    width: 100%;
    height: 100%;
    max-width: 120px;
    max-height: 60px;
    margin: 0 0 -15px 0;
    padding: 0;
  }
}

.event-visual {
  height: 50vh;
  min-height: 400px;
  max-height: 1000px;
  position: relative;
}

.event-logo {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .logo {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    padding: 10px 10px 0 10px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    &.partners {
      background: rgba(255, 255, 255, 1);
    }

    hr {
      color: $s-blue;
    }

    .org-logo-group {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    p {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding-top: 20px;
    }

    img {
      height: 18vw;
      &.organizing-logo {
        height: auto;
        max-width: 150px;
      }
      &.partner-logo {
        max-width: 130px;
      }
    }
  }

  .row {
    margin: 0px;

    .date-location {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background: $s-blue-light;
      border-radius: 3px;
      margin-top: 5px;
      padding: 2px 0px 8px 0px;
      text-transform: uppercase;
      width: 100%;
    }
  }
}

.veb-banner {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: rgb(18, 68, 145);
  background: linear-gradient(
    40deg,
    rgba(18, 68, 145, 1) 10%,
    rgba(3, 11, 69, 1) 89%
  );
  border-radius: 20px;
  margin: 0px 10px 25px 0px;
  padding: 20px;
  width: 98%;
}

.section-background {
  background: $gray-200;
  width: 100%;
}

.attendee-header {
  p {
    font-size: 1.1rem;
  }

  .reserve-ticket {
    background: $gray-200;
    border-radius: 10px;
    padding: 20px;
  }
}

.attendee-overview {
  margin-top: 20px;

  .attendee-logo {
    img {
      width: 100%;
    }
  }
}

.updates-popup {
  @include popup($white, $gray-800);
  border-radius: 10px;
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
  height: auto;
  max-width: 800px;
  width: 90%;

  .visual {
    background-position: center center;
    background-size: cover;
    border-radius: 10px 0 0 10px;
    position: relative;
    overflow: hidden;

    .logo {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      left: 50%;
      max-width: 300px;
      padding: 5px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      img {
        width: 100%;
      }
    }
  }

  .text {
    padding: 2rem 2rem 2rem 3rem;
  }
}

.event-description {
  margin-bottom: 20px;
}

.event-impression {
  text-align: center;
  width: 100%;

  img {
    border-radius: 20px;
    max-width: 600px;
    width: 100%;
  }
}

.event-metrics-divider {
  background: $s-orange;
  padding: 20px;

  p {
    &.metric {
      font-size: 5rem;
      line-height: 5.2rem;
    }
  }
}

.side-event-registration {
  background: $s-blue-muted;
  background-blend-mode: multiply;
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }
}

.updates-section {
  background: $s-blue-muted;
  background-blend-mode: multiply;
  width: 100%;

  .receive-updates {
    margin: 10px 0px 20px 0px;
  }
}

.speaker-box {
  @include box($white, $gray-800);
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: block;
  margin: 0px auto 30px auto;
  padding: 10px 0px 0px 0px;
  width: 300px;
  height: calc(100% - 30px);

  .speaker-image {
    height: 100%;
    aspect-ratio: 1/1;
    img {
      border-radius: 5px 75px 75px 5px;
      height: inherit;
      height: -webkit-fill-available;
      height: fill-available;
      margin: -10px 10px 0px 0px;
    }
  }

  .speaker-info {
    padding: 0 5px;
    text-align: end;
    p {
      color: $s-blue-light;
    }
  }

  .speaker-title {
  }

  .speaker-logo {
    text-align: end;
    img {
      margin: 0px 0px 0px 0px;
      width: 120px;
    }
  }

  .speaker-role-webinar {
    background-color: $sun-medium;
    border-radius: 5px;
    color: $white;
    float: right;
    font-size: 0.75rem;
    margin-top: -10px;
    margin-bottom: 5px;
    max-width: fit-content;
    padding: 1.5px 5px;
    position: relative;
    text-transform: capitalize;
  }
}

.advisory-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 25px;

  &.advisory-small {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.text-layout {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  max-width: 620px;

  &.hide-on-large {
    display: none;
  }

  &.hide-on-small {
    padding: 0 40px;
  }
}

.advisory-board {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;

  .advisory-name {
    min-height: 45px;
    text-align: center;
    width: 90px;
    p {
      margin: 0;
    }
  }
}

.advisory-large {
  width: 125px;
  .advisory-name {
    width: 100px;
  }
  .advisory-image {
    width: 100px;
  }
  .advisory-logo img {
    width: 100px;
  }
}

.advisory-image {
  border-radius: 50%;
  margin-bottom: 10px;
  transition: all 0.2s;
  width: 90px;
  &:hover {
    transform: scale(1.1);
  }
}
.advisory-logo img {
  width: 90px;
  margin: 0px;
}

.speaker-host {
  background: linear-gradient(
    to bottom,
    #f9d5ca 0%,
    #f9d5ca 50%,
    white 50%,
    white 100%
  );

  .conference-host {
    padding-top: 5px;
    padding-bottom: 0px;
  }
}

.speaker-popup {
  @include popup(unset, $gray-800);
  max-width: 800px;
  width: 90%;

  .close-popup {
    position: absolute;
    right: 0px;
    z-index: 5;

    i {
      color: $s-orange;
      cursor: pointer;
      font-size: 25px;
    }
  }

  .speaker-image {
    position: relative;

    img {
      border-radius: 5px;
      float: left;
      margin-left: 20px;
      height: 200px;
      width: 200px;
    }
  }

  .speaker-info {
    float: left;
    font-size: 1.2rem;
    padding: 0px 20px;
    width: calc(100% - 220px);
    p {
      color: $white;
    }
  }

  .speaker-logo {
    display: block;
    height: 120px;
    position: relative;
    top: 5px;

    img {
      width: 200px;
    }
  }

  .speaker-bio {
    @include box($white, $gray-800);
    margin-top: -115px;
    padding: 125px 20px 20px 20px;
    width: 100%;
  }
}

.resource-list {
  @include box($white, $gray-800);
  border-radius: 5px;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
  padding: 0px;
  width: 100%;

  a:nth-child(1) .resource-list-item,
  a:nth-child(2) .resource-list-item {
    border-bottom: 1px solid $gray-200;
  }

  .resource-list-item {
    padding: 10px;

    .resource-visual {
      border-radius: 10px;
      height: 75px;
      padding: 5px 0px;
      width: 100%;
    }

    .resource-title {
      margin-bottom: 0px;
    }
  }
}

.program-outline {
  .session-title {
    padding: 10px;
    .session-header {
      border-radius: 3px;
      display: inline-block;
      left: 0px;
      margin: 0px;
      max-width: unset;
      padding: 1px 10px 3px 0px;
      width: unset;
    }
  }

  .session-title:not(:last-child) {
    border-bottom: 1px solid $gray-200;
  }
}

.program-box {
  padding: 5% 3%;
  .row {
    display: flex;
    justify-content: space-between;
  }
}

.component-content {
  display: flex;
  align-items: center;
  justify-content: center;

  .img {
    max-width: 100%;
    min-width: 100%;
    transform: translate(20px, -20px);
  }
  .title {
    padding: 0 35px 0 0;
  }
  .description {
    color: #030b45;
    font-weight: 500;
    padding: 0 40px 35px 0;
  }
  .image-background {
    background-color: $s-orange;
    max-width: 80%;
    min-width: 75%;
    &.about {
      max-width: 90%;
    }
    &.newsletter {
      float: right;
      max-width: 50%;
    }
  }
  &.dinner {
    .session-time {
      background: $s-orange;
    }
    .session-title {
      font-size: 1.3rem;
    }
  }
}

.program-menu {
  @include box($white, $gray-800);
  border-radius: 10px;
  min-height: 50px;
  padding: 0px;
  width: 100%;

  .nav-link {
    color: $gray-800;
    border-radius: 20px;
    background-color: $white;
    transition: 0.5s ease-in-out;
    margin: 12px 25px 0px 25px;
  }

  .nav-link:hover {
    color: $gray-500;
  }

  .nav-pills {
    color: $gray-800;
    display: flex;
    justify-content: space-evenly;
    border-bottom: $white solid 2px;
    margin: 5px 25px 0px 25px;
    padding: 5px 5px -5px 0px;

    .active {
      background-color: #ffb546;
      color: $white;
      font-weight: 600;
    }
  }
}

.tab-content {
  width: 100%;
}

.program-outline-box {
  @include box($white, $gray-800);
  width: 100%;
  z-index: 10;
}

.program-date {
  position: relative;

  // Not a child of .mobile-application

  button {
    margin-bottom: 20px;
  }
}

body:not(.mobile_application) .program-date .day-link {
  position: absolute;
  top: -120px;

  @include media-breakpoint-down(sm) {
    top: -100px;
  }
}

body.mobile_application .program-date .day-link {
  position: absolute;
  top: -25px;
}

.simultaneous-translation {
  border: 3px solid $gray-200;
  border-radius: 100px;
  display: table;
  font-size: 1.2rem;
  margin: 0px auto 40px auto;
  max-width: 1000px;
  padding: 12px 20px 8px 10px;
  position: relative;
  text-align: center;

  .flags {
    float: left;

    img {
      border: 3px solid $gray-200;
      width: 50px;

      &:first-child {
        border-radius: 20px 0px 0px 20px;
        clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
        margin-top: -2px;
      }

      &:nth-child(2) {
        border-radius: 0px 20px 20px 0px;
        clip-path: polygon(25% 0, 100% 0%, 100% 100%, 0 100%);
        margin: -2px 15px 0px -17px;
      }
    }
  }
}

.session-header {
  margin: 10px auto 20px auto;
  position: relative;
  width: 100%;

  .session-number {
    border-radius: 3px;
    display: inline;
    margin-bottom: 10px;
    padding: 1px 10px 3px 10px;
    h2,
    p {
      display: inline;
    }
    .group {
      margin-left: 10px;
    }
  }
}

.session-section {
  border-radius: 5px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  margin: 0 0 20px 0;
  .room-name {
    border-radius: 5px;
    color: $gray-500;
    padding: 10px 10px 0px 20px;
    margin-left: 10px;
  }
  hr {
    color: rgba(0, 0, 0, 0.25);
  }
}

.program-session {
  display: flex;
  left: 17.5px;
  margin: 0px auto;
  position: relative;
  scroll-margin-top: 125px;
  width: 100%;

  .session-time {
    border-radius: 3px;
    color: $white;
    display: inline;
    float: left;
    font-size: 1.2rem;
    font-weight: 600;
    height: 33px;
    margin: 0px 10px 5px 0px;
    padding: 1px;
    text-align: center;
    width: 60px;
  }

  .session-details {
    margin: 10px 20px 10px 0;
    padding: 10px 20px 10px 0;
    position: relative;
    width: 100%;
    &.app {
      margin: 10px 0px;
      padding: 10px 5px 10px 0px;
    }

    .session-title {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 3px;
      padding: 0px 10px 0px 0px;
      width: calc(100% - 30px);
    }

    .session-meta {
      float: left;
      font-size: 1rem;
      padding: 5px 10px 5px 0px;
      color: $gray-500;
      &.break {
        color: $white;
        width: 100%;
      }
    }

    .session-flag {
      width: 20px;
      height: 20px;
      margin: -4px 5px 0px 0px;
      border-radius: 50%;
    }

    .session-description {
      margin-bottom: 10px;
      max-width: 100%;
      width: fit-content;
    }

    .session-speaker {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 10px;
      width: 100%;

      .speaker-image {
        float: left;
        padding-right: 10px;

        img {
          border-radius: 100px;
          width: 40px;
        }
      }

      .speaker-details {
        display: grid;

        .speaker-role {
          border-radius: 3px;
          color: $white;
          font-size: 0.75rem;
          height: 16px;
          margin-top: -18px;
          padding: 0px 3px;
          position: absolute;
          text-transform: capitalize;
        }
      }
    }

    .session-expand {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: 100%;
      i {
        font-size: 1.2rem;
        font-weight: 800;
        padding: 2px 5px;
        transition: transform 0.3s ease;
      }
      &.expanded {
        padding-top: 20px;
        #showSession {
          display: none;
        }
        #closeSession {
          display: block;
        }
      }

      #showSession {
        display: block;
      }
      #closeSession {
        display: none;
      }
    }

    .session-topics {
      display: none;
      float: left;
      margin-top: 10px;
      width: calc(100% - 40px);

      ul {
        font-size: 1rem;
        line-height: 1.5rem;
        list-style-type: disc !important; //override Bootstrap
      }
    }
  }
  &.workshop {
    .session-details {
      padding: 0px 0px 10px 0px;
      .session-title {
        display: table-cell;
        border-radius: 5px 5px 0px 0px;
        padding: 0px 80px 1px 0px;
        width: 100%;
        h2 {
          padding: 4px 6px;
          border-radius: 5px;
        }
      }

      .session-meta {
        padding: 5px 20px 5px 20px;
      }

      .session-description {
        padding: 0px 20px;
        width: 100%;
      }
    }
  }
  .session-meta-full-width {
    display: flex;
    flex-direction: row;
  }
  .partner-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    float: right;
    max-width: fit-content;
    img {
      width: 150px;
    }
  }

  &.break {
    background: $s-blue;
    border-radius: 5px;
    min-width: 100%;
    padding: 10px 20px 10px 10px;
    position: relative;
    width: 100%;
    left: 0;

    .session-time {
      background: $white;
      color: $s-blue;
      margin: 0 10px 5px 10px;
    }

    .session-description {
      p,
      span {
        color: white;
        padding: 10px;
      }
    }

    .session-details {
      box-shadow: unset;
      padding: 10px 20px 0px 0px;
      position: relative;
      width: 100%;

      .session-title {
        color: $white;
        display: contents;
        float: left;
        font-size: 1.4rem;
        font-weight: 500;
        width: calc(100% - 200px);
      }

      &.session-partner {
        min-height: 150px;
      }

      .partner-box {
        align-items: center;
        background: $white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        margin: 0 0 0 20px;
        padding: 5px;
        position: absolute;
        right: -20px;
        top: 0px;
        width: 240px;
        img {
          padding: 5px;
          width: 200px;
        }
        .partner-label {
          padding: 0px;
        }
      }
    }
  }
}

.dinner-time {
  background: $s-orange;
  border-radius: 3px;
  color: $white;
  display: inline;
  float: left;
  font-size: 1.2rem;
  font-weight: 600;
  height: 33px;
  margin: 5px 10px 0px 0px;
  padding: 1px;
  text-align: center;
  width: 60px;
}
.session-partner-dinner {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 90%;
  img {
    width: 200px;
  }
}
.program-icon {
  font-size: 0.8rem;
  padding: 5px;
}

.app-section {
  .app-download {
    height: 110px;
  }

  .app-rating {
    @include box($white, $gray-800);
    background: $white;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    text-align: center;

    i {
      color: $s-orange;
      font-size: 1.8rem;
    }
  }

  .app-animation {
    text-align: center;
  }
}

.venue-box,
.dinner-box {
  @include box($white, $gray-800);
  width: 75%;
  padding: 0px;

  .venue-visual {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    border-radius: 5px 0px 0px 5px;
    height: 220px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .venue-info {
    padding: 20px 25px;

    h4 {
      margin-bottom: 8px;
    }
    p {
      margin: 0px;
    }
    button {
      font-weight: 500;
    }
    a {
      transition: all 0.3s ease;
    }
    a:hover {
      color: $gray-500;
    }
  }
}

.venue-menu {
  @include box($white, $gray-800);
  border-radius: 10px;
  min-height: 50px;
  padding: 0px;
  width: 73.5%;

  .nav-link {
    color: $gray-800;
    border-radius: 20px;
    background-color: $white;
    transition: 0.5s ease-in-out;
  }

  .nav-link:hover {
    color: $gray-500;
  }

  .nav-pills {
    color: $gray-800;
    display: flex;
    justify-content: space-evenly;
    border-bottom: $white solid 2px;
    margin: 5px 15px 0px 15px;
    padding: 5px 5px -5px 0px;

    .active {
      background-color: $sun-light;
      color: $white;
      font-weight: 600;
    }
  }
}

.tab-content > .active {
  display: flex;
}

.dinner-box {
  .venue-visual {
    height: 180px;
  }
}

.accommodation-section {
  padding: 30px;
}

.ticket-section {
  background-image: linear-gradient(
      90deg,
      #030b45 11.02%,
      rgba(7, 27, 171, 0) 99.77%
    ),
    url('../static/images/events/registration-background.jpg');
  background-position: center center;
  padding: 2vh auto;

  .ticket-info {
    margin: 2rem 0 3rem 0;
    ul {
      margin: 0;
      padding-left: 1.5rem;
      li {
        color: $white;
        font-size: 1.2rem;
        line-height: 2.2rem;
        list-style-type: none;

        &.optional-add-on:before {
          color: $sun-light;
          content: '\2b';
          font-family: FontAwesome;
          font-size: 2.1rem;
          margin-right: 11px;
          position: relative;
          top: 5px;
        }

        &:not(.optional-add-on):before {
          color: $s-green;
          content: '\f00c';
          font-family: FontAwesome;
          font-size: 1.2rem;
          margin-right: 15px;
        }
      }
    }
  }

  .local-price-info {
    align-items: flex-start;
    display: flex;
    padding-top: 3px;
    position: absolute;
    max-width: 200px;

    i {
      background: $white;
      border: 1px solid $white;
      border-radius: 100px;
      color: $s-blue-light;
      font-size: 15px;
      margin-top: 3px;
    }

    p {
      padding-left: 10px;
    }
  }

  .upcoming-stage {
    background: rgba(163, 197, 228, 0.47);
    backdrop-filter: blur(7.5px);
    border-radius: 5px;
    padding: 10px 20px;
    position: relative;
  }
}

.preferred-rates {
  max-width: 700px;
}

.ticket-box {
  @include box($white, $s-blue-light);
  border-radius: 0;
  box-shadow: unset;
  margin: 0 10px;
  padding: 50px 0;
  text-align: center;

  &:first-child {
    background: $s-blue-light;
    border: 4px solid $s-blue-light;
  }

  &:not(:first-child) {
    margin: 40px 10px;
  }
}

.registration-box {
  @include box($white, $gray-800);
  width: 100%;

  .registration-visual {
    border-radius: 10px;
    height: 220px;
    position: relative;
    width: 100%;

    .registration-logo {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      left: 50%;
      padding: 5px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        height: 60px;
      }
    }
  }

  .registration-pricing {
    font-size: 1.1rem;
    padding: 15px;

    .line-through {
      font-weight: 400;
      margin: 0px;
      text-decoration: line-through;
      text-decoration-thickness: 1px;
    }

    a {
      color: $s-orange;
    }
  }

  .registration-info {
    padding: 20px;

    ul {
      list-style-type: disc;
    }

    button {
      margin-bottom: 20px;
    }
  }
}

.registration-section {
  .visual {
    background-position: center center;
    background-size: cover;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    .logo {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      left: 50%;
      max-width: 300px;
      padding: 5px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90%;

      img {
        width: 100%;
      }
    }
  }

  .registration-info {
    border: 3px solid #f9d5ca;
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 40px;
    padding: 20px;
    width: 100%;
  }

  .registration-side-note {
    background: #c5dbf2;
    border-radius: 20px;
    padding: 20px;
  }

  .registration-form {
    width: 100%;
  }
}

.foundation-registration {
  @include box($white, $gray-800);
  padding: 0px;
  width: 100%;

  img {
    display: flex;
    max-width: 400px;
    padding: 30px 40px 10px 40px;
    width: 100%;
  }

  .foundation-info {
    display: inline-block;
    p {
      color: #558388;
      padding: 5px 30px 0 30px;
      line-height: 1.4rem;
    }

    button {
      background-color: $s-foundation;
      border-color: $s-foundation;
      display: inline-block;
      margin: 0 0 20px 0;
      &:hover {
        background-color: $white;
        color: $s-foundation;
      }
    }
  }
}

.highlight-section {
  margin: 0px;
  max-height: 650px;
  min-height: 300px;
  position: relative;
  width: 100%;

  .highlight-image {
    clip-path: ellipse(30% 100% at 85% 55%);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: -webkit-fill-available;
    max-height: 650px;
    min-height: 300px;
    width: 100%;
  }

  button {
    box-shadow: none;
  }
}

.accommodation-info-popup,
.eager-speaker-popup {
  @include popup($white, $gray-800);
  box-shadow: 0 0.15rem 3.125rem rgba(0, 0, 0, 15%);
  max-width: 600px;
  padding: 20px;
  width: 90%;

  .close-popup {
    position: absolute;
    right: 0px;

    i {
      color: $s-orange;
      cursor: pointer;
      font-size: 25px;
    }
  }
}

.disclaimer {
  max-width: 850px;
  font-style: italic;
  color: #555555;
}

.accommodation-box {
  @include box($white, $gray-800);
  margin-top: 20px;
  padding: 20px;

  &.conference {
    background: $s-orange-muted;
  }

  .accommodation-title {
    height: 4rem;
    overflow: hidden;
  }

  img {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .accommodation-info {
    padding-right: 0px;
  }
}
.speaker-nomination-banner {
  background-color: $s-blue-light;
  p {
    color: white;
  }
}
.speaker-nomination {
  img {
    width: 100%;
    max-width: 340px;
  }
}

.partner-opportunities {
  margin-bottom: 40px;

  .partner-contact {
    .contact-picture {
      background-color: $s-yellow-muted;
      border-radius: 100px;
      box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 15%);
      height: 130px;
      width: 130px;
    }

    .contact-info {
      padding-top: 20px;
    }
  }
}

.partner-box {
  @include box($white, $gray-800);
  box-shadow: $s-box-shadow-light;
  margin: 10px;
  max-width: 280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .partner-logo {
    display: flex;
    align-items: center; // Center vertically
    justify-content: center;
    min-height: 120px;
    img {
      max-width: 100%;
    }
    flex: 0 1 100%;
  }

  .partner-logo {
    max-width: 100%;
  }

  .partner-info {
    background: $s-blue-muted;
    border-radius: 0px 0px 5px 5px;
    margin: 0px -10px -10px -10px;
    padding: 5px;
    text-align: center;

    .partner-role {
      color: $s-blue-light;
      text-transform: uppercase;
    }

    .partner-name {
      color: $s-blue-light;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

.mediapartners {
  margin-top: 40px;
}

.contact-title {
  margin-bottom: 50px;
  text-align: center;
}

.partnership-image {
  margin: 0px 10px 10px 0px;
  max-width: 400px;
  border-radius: 10px;
}

.contact-section {
  background: $white;

  .contact-box {
    margin-bottom: 20px;

    .contact-picture {
      border-radius: 100px;
      height: 120px;
      width: 120px;
      border-style: solid;
      border-width: 2px;
      border-color: $s-orange-muted;
    }
    .contact-details > p > b {
      border-style: none none solid none;
      border-width: 2px;
      border-color: $s-orange-muted;
    }

    &:nth-child(2) {
      .contact-picture {
        border-style: solid;
        border-width: 2px;
        border-color: $s-blue-muted;
      }
      .contact-details > p > b {
        border-style: none none solid none;
        border-width: 2px;
        border-color: $s-blue-muted;
      }
    }

    &:nth-child(3) {
      .contact-picture {
        border-style: solid;
        border-width: 2px;
        border-color: #d8eacc;
      }
      .contact-details > p > b {
        border-style: none none solid none;
        border-width: 2px;
        border-color: #d8eacc;
      }
    }

    .contact-details {
      padding: 0px 10px;

      p {
        margin: 10px 0px;
      }
    }
  }
}

.divider {
  height: 100px;
  margin: 100px 0px 0px 0px;
  width: 100%;
}

.past-event-banner {
  background: $gray-200;
  padding: 8px 12px;
  border-radius: 5px;
  width: 100%;

  &.orange {
    background: $s-orange;
    cursor: pointer;
  }
}

.partner-banner {
  background-color: $s-blue;
  width: 100%;
  display: block;
  margin: 0;
  p {
    margin: 0;
    padding: 20px;
  }
}

.thumbnail-scroller {
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  overflow-x: auto;
  padding: 10px 0;
  white-space: nowrap;
}

.thumbnails img {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 75px;
  margin-right: 3px;
  max-width: 150px;
  width: auto;
  &:hover {
    transform: scale(1.05);
    transition: transform 0.2s;
  }
}

.carousel {
  margin: 0px 30px;
  .carousel-indicators {
    bottom: -45px;
    button {
      background-color: $s-blue-light;
      border-radius: 50%;
      height: 10px;
      margin: 0px 8px;
      width: 10px;
    }
  }
}
.thumbnail-scroller {
  margin: 20px 30px;
}

@media only screen and (max-width: 1250px) and (min-width: 990px) {
  .event-nav {
    .nav-link {
      &:not(.language) {
        margin: 5px 12px 0px 12px;
        i {
          display: none;
        }
      }
      &.home {
        margin: 5px 0px 0px 0px;
        i {
          display: inline;
        }
      }
      &.tickets {
        margin-top: -5px;
      }
    }
  }
  .program-session {
    &.break {
      .partner-box {
        max-width: 200px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .advisory-board {
    .advisory-name {
      width: 100px;
    }
  }
  .advisory-image {
    width: 100px;
  }
  .advisory-logo img {
    width: 100px;
  }
}

@media only screen and (max-width: 1000px) {
  .simultaneous-translation {
    border: unset;

    .flags {
      float: unset;
      margin-bottom: 20px;
    }
  }

  .program-session {
    &.break,
    &.dinner {
      .session-details {
        .session-title {
          width: 100%;
          float: none;
        }
      }
    }
  }

  .text-layout {
    &.hide-on-small {
      display: none;
    }

    &.hide-on-large {
      display: block;
    }
  }
}

@media only screen and (max-width: 990px) {
  .event-nav {
    .language {
      button {
        margin: 0px 0px 0px -10px;
      }
    }
  }
  .event-logo {
    .logo {
      p {
        display: none;
      }
    }
  }
  .program-session .session-details,
  .program-session.break .session-details {
    .session-description {
      width: max-content;
    }
    .partner-box {
      height: unset;
      position: unset;
      width: 150px;
      img {
        width: 150px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .highlight-image {
    display: none;
  }
}

@media only screen and (max-width: 720px) {
  .event-logo .row .date-location {
    h2 {
      font-size: 1.5rem;
      line-height: 1.2rem;
    }

    &:first-child {
      border-radius: 3px 0px 0px 3px;
      margin: 4px 0px 0px 0px;
      width: 100%;
    }

    &:nth-child(2) {
      border-radius: 0px 3px 3px 0px;
      margin: 4px 0px 0px 0px;
      width: 100%;
    }
  }
  .session-venue {
    margin-bottom: 40px;
  }

  .event-logo {
    .logo {
      img {
        height: 18vw;
        &.organizing-logo {
          height: auto;
          max-width: 95px;
        }
        &.partner-logo {
          max-width: 85px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .program-session.break .session-details .session-title,
  .program-session.dinner .session-details .session-title {
    float: unset;
    width: calc(100% - 40px);
  }

  .event-logo .row .date-location {
    h2 {
      font-size: 1.2rem;
    }
  }

  .event-nav .language {
    padding-right: 0px;
    width: 90px;
    button {
      margin: -7px 0px 0px -10px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .speaker-popup {
    .speaker-bio {
      margin-top: 15px;
      padding: 15px 20px 20px 20px;
    }

    .speaker-info {
      width: 100%;
    }
  }

  .advisory-layout {
    flex-wrap: unset;
    justify-content: flex-start;
    overflow-x: auto;
    white-space: nowrap;
  }

  .advisory-board {
    margin: 0 20px;
    .advisory-name {
      min-height: 65px;
      text-align: center;
      width: 120px;
      p {
        margin: 0;
      }
    }
  }
  .advisory-image {
    width: 120px;
  }
  .advisory-logo img {
    width: 120px;
  }
}

@media only screen and (max-width: 536px) {
  .venue-menu {
    min-height: 100px;
    max-height: 120px;
  }

  .nav-pills {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .highlight-section {
    min-height: 350px;
  }
  .highlight-image {
    display: none;
  }
  .component-content {
    justify-content: flex-start;
    margin-top: 45px;
  }
  .partnership-image {
    max-width: 330px;
  }
  .speaker-popup {
    .speaker-image {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        border-radius: 20px;
      }
    }

    .speaker-info {
      padding: 15px 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .modal-body {
      height: 90vh;
    }
  }
}

@media only screen and (max-width: 391px) {
  .venue-menu {
    min-height: 150px;
    max-height: 170px;
  }
}

.profile-initials {
  background: $s-red;
  border-radius: 100px;
  color: $white;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;

  span {
    color: $white;
  }
}

.attendee-list-header {
  margin-top: -2rem;
}

.attendee-list-body {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  max-width: 100%;
  overflow-x: hidden;
}

.badge.organization-type-badge {
  padding: 4px 8px;
  text-wrap: wrap;
  width: auto;
}
